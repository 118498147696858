import {BenefitType, MrPlan, MrPlanBenefit} from '@peachy/core-domain-pure'

export class PlanAgent {

    private readonly alteredPlan: MrPlan

    constructor(public readonly currentPlan: MrPlan) {
        this.alteredPlan = structuredClone(currentPlan)
    }

    public addBenefit(
        benefit: MrPlanBenefit,
    ) {
        this.assertHasNotBenefit(benefit.id)
        this.alteredPlan.benefits[benefit.id] = benefit
    }

    public removeBenefit(
        benefitId: BenefitType,
    ) {
        this.assertHasBenefit(benefitId)
        delete this.alteredPlan.benefits[benefitId]
    }

    public alterBenefitLimit(
        benefitId: BenefitType,
        newLimit: number,
    ) {
        this.assertHasBenefit(benefitId)
        this.assertIsValidLimit(benefitId, newLimit)
        this.alteredPlan.benefits[benefitId].limit = newLimit
    }

    public getAlteredPlan() {
        return this.alteredPlan
    }

    // -----------------------

    private assertIsValidLimit(benefitId: string, limit: number) {
        // does this benefit support limits?
        // is limit in range for benefit?
    }


    private assertHasBenefit(benefitId: string) {
        if (!(benefitId in this.alteredPlan.benefits)) {
            throw `Benefit ${benefitId} does not exist on plan ${this.alteredPlan.id}`
        }
    }


    private assertHasNotBenefit(benefitId: string) {
        if (benefitId in this.alteredPlan.benefits) {
            throw `Benefit ${benefitId} already exists on plan ${this.alteredPlan.id}`
        }
    }
    private assertIsPlan(planId: string) {
        if (this.currentPlan.id !== planId) {
            throw `Current plan ${this.currentPlan.id} does not match ${planId}`
        }
    }
}

import {HasLifecycleStatus, MrLife, MrLifeBenefit, MrPlanBenefit} from '@peachy/core-domain-pure'

import {isNullish} from '@peachy/utility-kit-pure'
import {AlterationDiff} from '../alteration/diff/AlterationDiff'


export const hasBeenCancelled = <T extends HasLifecycleStatus>(diff: AlterationDiff<T>) =>
    diff.left?.status === 'ACTIVE' && diff.right?.status === 'CANCELLED'
export const hasBeenAdded = <T extends HasLifecycleStatus>(diff: AlterationDiff<T>) =>
    isNullish(diff.left) && (diff.right?.status === 'ACTIVE') && !hasBeenTransferredIn(diff)
export const hasBeenReactivated = <T extends HasLifecycleStatus>(diff: AlterationDiff<T>) =>
    diff.left?.status === 'CANCELLED' && (diff.right?.status === 'ACTIVE')

export const hasBeenRenewed = <T extends (HasLifecycleStatus)>(diff: AlterationDiff<T>) =>
    (diff.left?.status === 'ACTIVE' && diff.right?.status === 'ACTIVE')
    && diff.left.nextRenewalDate !== diff.right.nextRenewalDate
    && (
        'effectiveDate' in diff.left && 'effectiveDate' in diff.right
            ? diff.left.effectiveDate < diff.right.effectiveDate
            : true
    )

export const hasBeenTransferredIn =<T extends (HasLifecycleStatus)>(diff: AlterationDiff<T>) =>
    isNullish(diff.left) && (diff.right?.status === 'ACTIVE') && (diff.right?.transfer?.in)

export const hasBeenTransferredOut =<T extends (HasLifecycleStatus)>(diff: AlterationDiff<T>) =>
    !isNullish(diff.left) && (diff.right?.status === 'ACTIVE') && (diff.right?.transfer?.out)



export const hasBenefitLimitIncreased = (diff: AlterationDiff<MrLifeBenefit> | AlterationDiff<MrPlanBenefit>) =>
    (!isNullish(diff.left?.limit) && !isNullish(diff.right?.limit))
    && diff.left.limit < diff.right.limit

export const hasBenefitLimitDecreased = (diff: AlterationDiff<MrLifeBenefit> | AlterationDiff<MrPlanBenefit>) =>
    (!isNullish(diff.left?.limit) && !isNullish(diff.right?.limit))
    && diff.left.limit > diff.right.limit


export const hasBenefitExcessIncreased = (diff: AlterationDiff<MrLifeBenefit> | AlterationDiff<MrPlanBenefit>) =>
    (!isNullish(diff.left?.excess) && !isNullish(diff.right?.excess))
    && diff.left.excess < diff.right.excess

export const hasBenefitExcessDecreased = (diff: AlterationDiff<MrLifeBenefit> | AlterationDiff<MrPlanBenefit>) =>
    (!isNullish(diff.left?.excess) && !isNullish(diff.right?.excess))
    && diff.left.excess > diff.right.excess

export const hasLifeAddressChanged = (diff: AlterationDiff<MrLife>) =>
    diff.left?.address && diff.right?.address


export const hasLifeBirthdateChanged = (diff: AlterationDiff<MrLife>) =>
    diff.left?.dateOfBirth && diff.right?.dateOfBirth


export const hasLifeNameChanged = (diff: AlterationDiff<MrLife>) =>
    diff.left?.firstname && diff.right?.firstname
    || diff.left?.lastname && diff.right?.lastname


export const hasLifeSwitchedPlan = (diff: AlterationDiff<MrLife>) =>
    diff.left?.planId && diff.right?.planId
    && diff.left.planId !== diff.right.planId

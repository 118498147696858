import {
    HasLifecycleStatus,
    isActive,
    isCancelled,
    LifecycleStatus,
    MrPolicy,
    MrSubscription,
    toClass, toPlain
} from '@peachy/core-domain-pure'
import {reconcileSubscriptionModelAlteration} from '../reconciliation/reconcileSubscriptionModelAlteration'


import {AlterationDiff} from './diff/AlterationDiff'
import {gatherSubscriptionAlterations} from '../reconciliation/gather-alterations/gatherSubscriptionAlterations'

import {LifeAlterationAgent} from './LifeAlterationAgent'
import {Alteration, AlterationAttribution} from '../alteration-kit/alteration-types'
import {ensureCancellationStatus} from '../reconciliation/valid-alterations/cancellation'
import {ensureActiveStatus} from '../reconciliation/valid-alterations/reactivation'
import {PolicyAlterationAgent} from './PolicyAlterationAgent'
import {transferInStatus, transferOutStatus} from '../reconciliation/valid-alterations/transfer'
import { PlanAgent } from './PlanAgent'
import { values } from '@peachy/utility-kit-pure'

export class SubscriptionAlterationAgent {

    private diff: AlterationDiff<MrSubscription, MrSubscription>
    private alteredSubscription: MrSubscription

    constructor(
        private accountId: string,
        private accountType: string,
        private currentSubscription: MrSubscription,
        public readonly attribution: AlterationAttribution
    ) {
        if (currentSubscription) {
            this.alteredSubscription = toPlain(currentSubscription)
            this.alteredSubscription.versionIdx = currentSubscription.versionIdx + 1
            this.alteredSubscription.versionDate = attribution.effectiveDate
        }
    }

    public alterSubscription(alteredSubscription: MrSubscription) {
        this.alteredSubscription = toPlain(alteredSubscription)
        this.reconcile()
    }


    public withPolicyAlterationAgent(policyId: string, alter: (policyAlterationAgent: PolicyAlterationAgent) => void) {
        this.assertHasPolicy(policyId)
        const policy = this.alteredSubscription.policies[policyId]
        const policyAlterationAgent = new PolicyAlterationAgent(policy, this.alteredSubscription, this.attribution)
        alter(policyAlterationAgent)
        this.alteredSubscription.policies[policy.id] = policyAlterationAgent.getAlteredPolicy()
        this.reconcile()
    }

    public withLifeAlterationAgent(lifeId: string, alter: (lifeAlterationAgent: LifeAlterationAgent) => void) {
        const [life, policy] = toClass(this.alteredSubscription, MrSubscription).resolveLifeAndPolicy(lifeId)
        if (!life) throw `Life ${lifeId} does not exist on subscription ${this.alteredSubscription.id}`
        const lifeAlterationAgent = new LifeAlterationAgent(life, this.alteredSubscription, this.attribution)
        alter(lifeAlterationAgent)
        this.alteredSubscription.policies[policy.id].lives[life.id] = lifeAlterationAgent.getAlteredLife()
        this.reconcile()
    }

    public withPlanAlterationAgent(planId: string, alter: (planAlterationAgent: PlanAgent) => void) {
        const plan = this.alteredSubscription.plans[planId]
        const planAlterationAgent = new PlanAgent(plan)
        alter(planAlterationAgent)
        this.alteredSubscription.plans[planId] = planAlterationAgent.getAlteredPlan()
        this.reconcile()
    }

    public clearPricing() {
        this.alteredSubscription.totalMonthlyPremium = null
        values(this.alteredSubscription.policies).forEach(policy => {
            policy.totalMonthlyPremium = null
            values(policy.lives).forEach(life => {
                life.totalMonthlyPremium = null
                values(life.benefits).forEach(benefit => {
                    benefit.premium = null
                })
            })
        })
        this.reconcile()
    }


    public addPolicy(policy: MrPolicy) {
        this.assertHasNotPolicy(policy.id)
        this.alteredSubscription.policies[policy.id] = policy
        this.reconcile()
        this.diff = null
    }


    public cancelPolicy(
        policyId: string,
        cancellationReason: string
    ) {

        this.assertHasPolicy(policyId)
        const policy = this.alteredSubscription.policies[policyId]
        if (!isCancelled(policy)) {
            ensureCancellationStatus(this.alteredSubscription.policies[policyId], this.attribution.effectiveDate, cancellationReason)
        }
        this.reconcile()
        this.diff = null
    }

    public reactivatePolicy(
        policyId: string,
    ) {
        this.assertHasPolicy(policyId)
        this.assertHasStatus(this.alteredSubscription, 'ACTIVE')

        const policy = this.alteredSubscription.policies[policyId]
        if (!isCancelled(policy)) {
            ensureActiveStatus(this.alteredSubscription.policies[policyId])
        }
        this.reconcile()
        this.diff = null
    }


    public transferOutPolicy(
        policyId: string,
        to: string,
        transferReason: string
    ) {
        const policy = this.alteredSubscription.policies[policyId]
        this.assertHasPolicy(policyId)
        this.assertHasStatus(policy, 'ACTIVE')

        policy.transfer = transferOutStatus(to, this.attribution.effectiveDate, transferReason)
        this.reconcile()

        policy.transfer = null

        this.diff = null
        return toPlain(policy)
    }


    public transferInPolicy(
        policy: MrPolicy,
        from: string,
        transferReason: string,
    ) {
        const transferredPolicy = toPlain(policy)
        this.assertHasNotPolicy(transferredPolicy.id)
        this.assertHasStatus(transferredPolicy, 'ACTIVE')
        transferredPolicy.transfer = transferInStatus(from, this.attribution.effectiveDate, transferReason)
        delete transferredPolicy.totalMonthlyPremium
        this.alteredSubscription.policies[transferredPolicy.id] = transferredPolicy
        this.reconcile()
        this.diff = null
    }


    public cancelSubscription(
        cancellationReason: string
    ) {
        if (!isCancelled(this.alteredSubscription)) {
            ensureCancellationStatus(this.alteredSubscription, this.attribution.effectiveDate, cancellationReason)
        }
        this.reconcile()
        this.diff = null
    }

    public reactivateSubscription() {

        if (!isActive(this.alteredSubscription)) {
            ensureActiveStatus(this.alteredSubscription)
        }
        this.reconcile()
        this.diff = null
    }


    public getAlteredSubscription() {
        return this.alteredSubscription
    }

    public reconcile() {
        this.alteredSubscription = reconcileSubscriptionModelAlteration(this.currentSubscription, this.alteredSubscription, this.attribution.effectiveDate)
    }


    private assertHasStatus(item: HasLifecycleStatus, status: LifecycleStatus) {
        if (item.status !== status) {
            throw `Item does not have status ${status}`
        }
    }


    private assertHasPolicy(policyId: string) {
        if (!(policyId in this.alteredSubscription.policies)) {
            throw `Policy ${policyId} does not exists on subscription ${this.alteredSubscription.id}`
        }
    }

    private assertHasNotPolicy(policyId: string) {
        if (policyId in this.alteredSubscription.policies) {
            throw `Policy ${policyId} already exists on subscription ${this.alteredSubscription.id}`
        }
    }

    public async alterationDiff() {
        return this.diff ??= await AlterationDiff.between(this.currentSubscription, this.alteredSubscription)
    }

    public async gatherAlterations(): Promise<Alteration[]> {
        const subscriptionDiff = await this.alterationDiff()
        return gatherSubscriptionAlterations(
            this.accountId,
            this.alteredSubscription,
            subscriptionDiff,
            this.attribution
        )
    }
}

import {
    BenefitType,
    MrLife,
    MrLifeBenefit,
    MrPlan,
    MrPlanBenefit,
    MrPolicy,
    MrSubscription,
} from '@peachy/core-domain-pure'

import { keySet, unique } from '@peachy/utility-kit-pure'
import * as Sets from 'mnemonist/set'
import {
    hasBeenAdded,
    hasBeenCancelled,
    hasBeenReactivated,
    hasBeenRenewed,
    hasBeenTransferredIn,
    hasBeenTransferredOut,
    hasBenefitExcessDecreased,
    hasBenefitExcessIncreased,
    hasBenefitLimitDecreased,
    hasBenefitLimitIncreased,
    hasLifeAddressChanged,
    hasLifeBirthdateChanged,
    hasLifeSwitchedPlan
} from '../../alteration-kit/alteration-predicates'
import { Alteration, AlterationAttribution, AlterationType } from '../../alteration-kit/alteration-types'
import { AlterationDiff } from '../../alteration/diff/AlterationDiff'
import { tracePath } from '../../alteration/diff/paths'



export function gatherSubscriptionAlterations(
    accountId: string,
    alteredSubscription: MrSubscription,
    subscriptionDiff: AlterationDiff<MrSubscription>,
    attribution: AlterationAttribution,
): Alteration[] {
    const alterationTemplate: Partial<Alteration> = {
        ...attribution,
        versionIdx: alteredSubscription.versionIdx,
        id: alteredSubscription.id,
        path: [accountId]
    }

    const alterationTypes: AlterationType[] = []


    if (hasBeenAdded(subscriptionDiff)) {
        alterationTypes.push('SUBSCRIPTION-ADDED')

    } else if (hasBeenTransferredIn(subscriptionDiff)) {
        alterationTypes.push('SUBSCRIPTION-TRANSFERRED-IN')

    } else if (hasBeenTransferredOut(subscriptionDiff)) {

        alterationTypes.push('SUBSCRIPTION-TRANSFERRED-OUT')

    } else if (hasBeenCancelled(subscriptionDiff)) {
        alterationTypes.push('SUBSCRIPTION-CANCELLED')

    } else if (hasBeenReactivated(subscriptionDiff)) {
        alterationTypes.push('SUBSCRIPTION-REACTIVATED')

    } else if (hasBeenRenewed(subscriptionDiff)) {
        alterationTypes.push('SUBSCRIPTION-RENEWED')
    }

    const planAlterations =
        keyDiff(subscriptionDiff.left?.plans, subscriptionDiff.right?.plans).all.flatMap(
            (planId) => gatherPlanAlterations(
                subscriptionDiff.subDiff(tracePath<MrSubscription>().plans[planId].$()),
                'SUBSCRIPTION-PLAN',
                planId,
                alterationTemplate
            )
        )

    const policyAlterations =
        keyDiff(subscriptionDiff.left?.policies, subscriptionDiff.right?.policies).all.flatMap(
            (policyId) => gatherPolicyAlterations(
                subscriptionDiff.subDiff(tracePath<MrSubscription>().policies[policyId].$()),
                policyId,
                alterationTemplate
            )
        )

    if (!alterationTypes.length && (planAlterations.length || policyAlterations.length)) {
        alterationTypes.push('SUBSCRIPTION-ALTERED')
    }
    return [
        ...mapAlterations(alterationTypes, alterationTemplate),
        ...policyAlterations,
        ...planAlterations
    ]
}



function mapAlterations(
    alterationTypes: AlterationType[],
    alterationTemplate: Partial<Alteration>,
    // from?: unknown,
    // to?: unknown
): Alteration[] {
    return unique(alterationTypes).map(
        type => ({...alterationTemplate, type} as Alteration)
    )
}


function gatherPolicyAlterations(
    policyDiff: AlterationDiff<MrPolicy>,
    policyId: string,
    alterationTemplate: Partial<Alteration>
): Alteration[] {

    alterationTemplate = childTemplate(policyId, alterationTemplate)
    const alterationTypes: AlterationType[] = []
    if (hasBeenAdded(policyDiff)) {
        alterationTypes.push('POLICY-ADDED')
    } else if (hasBeenTransferredIn(policyDiff)) {
        alterationTypes.push('POLICY-TRANSFERRED-IN')
    } else if (hasBeenTransferredOut(policyDiff)) {
        alterationTypes.push('POLICY-TRANSFERRED-OUT')
    } else if (hasBeenCancelled(policyDiff)) {
        alterationTypes.push('POLICY-CANCELLED')
    } else if (hasBeenReactivated(policyDiff)) {
        alterationTypes.push('POLICY-REACTIVATED')
    } else if (hasBeenRenewed(policyDiff)) {
        alterationTypes.push('POLICY-RENEWED')
    }

    const lifeAlterations =
        keyDiff(policyDiff.left?.lives, policyDiff.right?.lives).all.flatMap(
            (lifeId) => gatherLifeAlterations(
                policyDiff.subDiff(tracePath<MrPolicy>().lives[lifeId].$()),
                lifeId,
                alterationTemplate
            )
        )

    const planAlterations =
        keyDiff(policyDiff.left?.plans, policyDiff.right?.plans).all.flatMap(
            (planId) => gatherPlanAlterations(
                policyDiff.subDiff(tracePath<MrPolicy>().plans[planId].$()),
                'POLICY-PLAN',
                planId,
                alterationTemplate
            )
        )

    if (!alterationTypes.length && (planAlterations.length || lifeAlterations.length)) {
        alterationTypes.push('POLICY-ALTERED')
    }

    return [
        ...mapAlterations(alterationTypes, alterationTemplate),
        ...planAlterations,
        ...lifeAlterations,
    ]
}


function gatherPlanAlterations(
    diff: AlterationDiff<MrPlan>,
    planType: 'SUBSCRIPTION-PLAN' | 'POLICY-PLAN',
    planId: string,
    alterationTemplate: Partial<Alteration>
) {

    alterationTemplate = childTemplate(planId, alterationTemplate)
    const alterationTypes: AlterationType[] = []

    if (diff.left && !diff.right) {
        alterationTypes.push(`${planType}-REMOVED`)
    } else if (!diff.left && diff.right) {
        alterationTypes.push(`${planType}-ADDED`)
    }

    const planBenefitAlterations =
        keyDiff(diff.left?.benefits, diff.right?.benefits).all.flatMap(
            (benefitId) => gatherPlanBenefitAlterations(
                diff.subDiff(tracePath<MrLife>().benefits[benefitId as BenefitType].$()),
                benefitId,
                planType,
                alterationTemplate
            )
        )

    if (!alterationTypes.length && planBenefitAlterations.length) {
        alterationTypes.push(`${planType}-ALTERED`)
    }

    return [
        ...mapAlterations(alterationTypes, alterationTemplate),
        ...planBenefitAlterations
    ]
}


function gatherLifeAlterations(
    lifeDiff: AlterationDiff<MrLife>,
    lifeId: string,
    alterationTemplate: Partial<Alteration>
): Alteration[] {

    alterationTemplate = childTemplate(lifeId, alterationTemplate)

    const alterationTypes: AlterationType[] = []
    const lifePropertyAlterationTypes: AlterationType[] = []

    if (hasBeenAdded(lifeDiff)) {
        alterationTypes.push('LIFE-ADDED')
    } else if (hasBeenTransferredIn(lifeDiff)) {
        alterationTypes.push('LIFE-TRANSFERRED-IN')
    } else if (hasBeenTransferredOut(lifeDiff)) {
        alterationTypes.push('LIFE-TRANSFERRED-OUT')
    } else if (hasBeenCancelled(lifeDiff)) {
        alterationTypes.push('LIFE-CANCELLED')
    } else if (hasBeenReactivated(lifeDiff)) {
        alterationTypes.push('LIFE-REACTIVATED')
    } else if (hasBeenRenewed(lifeDiff)) {
        alterationTypes.push('LIFE-RENEWED')
    }

    if (hasLifeSwitchedPlan(lifeDiff)) {
        lifePropertyAlterationTypes.push('LIFE-PLAN-SWITCHED')
    }
    if (hasLifeAddressChanged(lifeDiff)) {
        lifePropertyAlterationTypes.push('LIFE-ADDRESS-UPDATED')
    }
    if (hasLifeBirthdateChanged(lifeDiff)) {
        lifePropertyAlterationTypes.push('LIFE-BIRTHDATE-UPDATED')
    }

    const benefitAlterations =
        keyDiff(lifeDiff.left?.benefits, lifeDiff.right?.benefits).all.flatMap(
            (benefitId) => gatherLifeBenefitAlterations(
                lifeDiff.subDiff(tracePath<MrLife>().benefits[benefitId as BenefitType].$()),
                benefitId,
                alterationTemplate
            )
        )

    if (!alterationTypes.length && (lifePropertyAlterationTypes.length || benefitAlterations.length)) {
        alterationTypes.push('LIFE-ALTERED')
    }

    return [
        ...mapAlterations([
                ...alterationTypes,
                ...lifePropertyAlterationTypes
            ], alterationTemplate
        ),
        ...benefitAlterations
    ]
}


function gatherLifeBenefitAlterations(
    benefitDiff: AlterationDiff<MrLifeBenefit>,
    benefitId: string,
    alterationTemplate: Partial<Alteration>
): Alteration[] {

    alterationTemplate = childTemplate(benefitId, alterationTemplate)
    const alterationTypes: AlterationType[] = []

    if (hasBeenAdded(benefitDiff)) {
        alterationTypes.push('LIFE-BENEFIT-ADDED')
    } else if (hasBeenTransferredIn(benefitDiff)) {
        alterationTypes.push('LIFE-BENEFIT-TRANSFERRED-IN')
    } else if (hasBeenTransferredOut(benefitDiff)) {
        alterationTypes.push('LIFE-BENEFIT-TRANSFERRED-OUT')
    } else if (hasBeenCancelled(benefitDiff)) {
        alterationTypes.push('LIFE-BENEFIT-CANCELLED')
    } else if (hasBeenReactivated(benefitDiff)) {
        alterationTypes.push('LIFE-BENEFIT-REACTIVATED')
    } else if (hasBeenRenewed(benefitDiff)) {
        alterationTypes.push('LIFE-BENEFIT-RENEWED')
    }
    const benefitPropertyAlterationTypes = gatherBenefitPropertyAlterationTypes(benefitDiff, 'LIFE')
    alterationTypes.push(...benefitPropertyAlterationTypes)

    return mapAlterations(alterationTypes, alterationTemplate)
}


function gatherPlanBenefitAlterations(
    diff: AlterationDiff<MrPlanBenefit>,
    benefitId: string,
    planType: 'SUBSCRIPTION-PLAN' | 'POLICY-PLAN',
    alterationTemplate: Partial<Alteration>
): Alteration[] {

    alterationTemplate = childTemplate(benefitId, alterationTemplate)
    const alterationTypes: AlterationType[] = []

    if (!diff.left && diff.right) {
        alterationTypes.push(`${planType}-BENEFIT-ADDED`)
    } else if (diff.left && !diff.right) {
        alterationTypes.push(`${planType}-BENEFIT-REMOVED`)
    }
    const benefitPropertyAlterationTypes = gatherBenefitPropertyAlterationTypes(diff, planType)
    alterationTypes.push(...benefitPropertyAlterationTypes)

    return mapAlterations(alterationTypes, alterationTemplate)
}


function gatherBenefitPropertyAlterationTypes(
    diff: AlterationDiff<MrPlanBenefit> | AlterationDiff<MrLifeBenefit>,
    benefitType: 'LIFE' | 'SUBSCRIPTION-PLAN' | 'POLICY-PLAN'
): AlterationType[] {
    const benefitAlterationTypes: AlterationType[] = []

    if (hasBenefitLimitIncreased(diff)) {
        benefitAlterationTypes.push(`${benefitType}-BENEFIT-LIMIT-INCREASED`)
    } else if (hasBenefitLimitDecreased(diff)) {
        benefitAlterationTypes.push(`${benefitType}-BENEFIT-LIMIT-DECREASED`)
    }

    if (hasBenefitExcessIncreased(diff)) {
        benefitAlterationTypes.push(`${benefitType}-BENEFIT-EXCESS-INCREASED`)
    } else if (hasBenefitExcessDecreased(diff)) {
        benefitAlterationTypes.push(`${benefitType}-BENEFIT-EXCESS-DECREASED`)
    }

    if (benefitAlterationTypes.length) {
        benefitAlterationTypes.push(`${benefitType}-BENEFIT-ALTERED`)
    }

    return benefitAlterationTypes
}



function keyDiff<L, R>(left: L, right: R) {

    const leftKeys = keySet(left)
    const rightKeys = keySet(right)

    const added = Sets.difference<string>(rightKeys, leftKeys)
    const removed = Sets.difference<string>(leftKeys, rightKeys)
    const common = Sets.intersection<string>(leftKeys, rightKeys)
    const all = Sets.union<string>(leftKeys, rightKeys)

    return {
        added: [...added],
        common: [...common],
        removed: [...removed],
        all: [...all]
    }
}


function childTemplate(id: string, alterationTemplate: Partial<Alteration>): Partial<Alteration> {
    return {
        ...alterationTemplate,
        path: [...alterationTemplate.path, alterationTemplate.id],
        id: id
    }
}
